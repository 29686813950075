<template>
  <HeaderHome />
  <div class="container-fluid body">
    <div class="container">
      <Loader :show="loading" />

      <div class="row body-box">
        <div class="col-md-12" ref="lote">
          <h1>Carrinho de compras</h1>
          <p>Confira abaixo os itens do seu carrinho de compras:</p>
        </div>

        <div v-if="orders_infos.length && orders.length">
          <div class="col-md-12 mt-4" v-for="(order, index) in orders_infos" :key="index">
            <div class="card mb-3">
              <div class="row no-gutters">
                <div class="col-md-3 col-xs-12 img-card padding-right-0">
                  <img :src="order.evento?.image" class="card-img" alt="">
                </div>

                <div class="col-md-9  col-xs-12">
                  <div class="card-body">
                    <div class="row">
                      <div class="col-md-10 col-xs-12">
                        <p class="card-text">
                          <b>Evento :</b> {{ order.evento?.name }} <br>
                          <b>Data de realização :</b> {{ order.evento?.start_at ? order.evento?.start_at.split('-')[2] +
                            '/' + order.evento?.start_at.split('-')[1] + '/' + order.evento?.start_at.split('-')[0] : '' }}
                          <br>
                          <b>Kit:</b> {{ order.kit?.name }} - <span v-if="orders[index] && orders[index]?.tamanho">{{
                            orders[index]?.tamanho }}</span> <br>
                          <b>Modalidade:</b> {{ order.modalidade?.name }} <br>
                          <b>Lote:</b> {{ order.lote?.name }} <br>
                          <span v-if="order.usuario?.id != current_userid">
                            <b>Usuário:</b> {{ order.usuario?.email }} <br>
                          </span>
                          <span v-if="orders[index] && orders[index]?.codigo">
                            <b>Código:</b> {{ orders[index]?.codigo }} <br>
                          </span>
                        </p>
                      </div>
                      <div class="col-md-2 price">
                        <b>R$</b> {{ new Intl.NumberFormat('pt-BR', { minimumFractionDigits: 2 }).format(order.kit?.price)
                        }}
                        <br>
                        <br>
                        <span @click="remove_item(index)">Remover Item</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div v-else>
          <div class="col-md-12 mt-4">
            <div class="card mb-3">
              <div class="card-body text-center">
                Seu Carrinho está vazio.
              </div>
            </div>
          </div>
        </div>


        <div class="col-md-12 mt-2 pr-4 text-right valor">
          <h3><span>Taxa de Inscrição: R$ {{ new Intl.NumberFormat('pt-BR', {
            minimumFractionDigits: 2
            , maximumFractionDigits: 2
          }).format(tax_tkx) }}</span></h3>
        </div>
        <div class="col-md-12 mt-2 pr-4 text-right valor">
          <h3><b>Total:</b> <span>R$ {{ new Intl.NumberFormat('pt-BR', {
            minimumFractionDigits: 2, maximumFractionDigits
              : 2
          }).format(price_order) }}</span></h3>
        </div>

        <div class="col-md-12 mt-4">
          <div class="row">
            <div class="col-md-4 mt-2" v-if="lastevent">
              <button class="btn btn-primary" @click="order_user">ADQUIRIR PARA OUTRO USUÁRIO</button>
            </div>
            <div class="col-md-4 text-right  mt-2" v-if="lastevent">
              <button class="btn btn-primary" @click="goto_event">VOLTAR AO EVENTO</button>
            </div>
            <div class="col-md-4 text-right  mt-2">
              <button class="btn btn-success" v-if="orders_infos.length && goto_pay"
                @click="goto_payment">PRÓXIMO
                PASSO</button>

              <button class="btn btn-success" v-if="orders_infos.length && goto_pay == false"
                @click="goto_finish">FINALIZAR PEDIDO</button>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>

  <Newsletter />
  <Footer />
  <FooterEnd />
</template>

<script>
import { defineComponent } from "vue";
import HeaderHome from "../components/HeaderHome";
import Footer from "../components/Footer.vue";
import FooterEnd from "../components/FooterEnd.vue";
import Newsletter from "../components/Newsletter.vue";
import Loader from "../components/Loader";
import Orders from "../api/Orders";
import Carrinho from "../api/Carrinho";
import Auth from "../api/Auth";
import User from "../api/User";
import Swal from "sweetalert2";

export default defineComponent({
  name: "CarrinhoPage",
  components: {
    HeaderHome,
    Footer,
    FooterEnd,
    Newsletter,
    Loader
  },
  computed: {

  },
  data() {
    return {
      "loading": true,
      "orders": [],
      "orders_infos": [],
      "price_order": 0,
      "tax_tkx": 0,
      'current_userid': null,
      "goto_pay": true,
      "lastevent": null,
    };
  },
  methods: {
    order_user() {
      Swal.fire({
        title: 'Entre com o E-mail do outro Usuário.',
        input: 'email',
        inputAttributes: {
          autocapitalize: 'off'
        },
        showCancelButton: true,
        confirmButtonText: 'Ir para compra',
        cancelButtonText: 'Cancelar',
        showLoaderOnConfirm: true,
        preConfirm: (login) => {
          return User.get_userid(login, Auth.token())
            .then(response => {
              if (!response.status == 200) {
                throw new Error(response.statusText)
              }
              return response.data
            })
            .catch(error => {
              Swal.showValidationMessage(
                `Request failed: ${error}`
              )
            })
        },
        allowOutsideClick: () => !Swal.isLoading()
      }).then((result) => {
        if (result.isConfirmed) {
          if (result.value.id && result.value.id != Auth.user().id) {
            window.localStorage.setItem('user_buy', result.value.id);

            setTimeout(() => {
              window.scrollTo(0, 0);
              this.$router.push(this.lastevent);
            }, 500);

          } else {
            Swal.fire({
              title: `Usuário não foi encontrado em nossa base de dados.`,
            })
          }

        }
      })
    },
    goto_event() {

      this.loading = true;
      let lastevent = this.lastevent.replace('/escolha-o-kit/', '/evento/');
      setTimeout(() => {
        window.scrollTo(0, 0);
        this.$router.push(lastevent);
      }, 500);
    },
    goto_payment() {

      window.scrollTo(0, 0);
      this.$router.push('/pagamento/');
    },
    goto_finish() {

      this.loading = true;
      Orders.make_order({ "orders": this.orders, }, Auth.token()).then((request) => {
        if (request.status == 200) {
          window.scrollTo(0, 0);
          window.localStorage.setItem('last-cart', JSON.stringify(request.data));
          Carrinho.limpar();

          this.loading = false;
          this.$router.push('/sucesso/');
        }
      });
    },
    submit() {
      this.$refs.form.reportValidity();
    },
    remove_item(index) {
      this.loading = true;

      Carrinho.remove_item(index);
      this.orders = Carrinho.get_itens();

      this.price_order = 0;
      this.tax_tkx = 0;
      Orders.get_itens(this.orders, Auth.token()).then((request) => {
        if (request.status == 200) {
          this.orders_infos = request.data.orders;
          this.price_order = request.data.price;

          this.loading = false;
        }
      });

    }
  },
  mounted() {
    this.orders = Carrinho.get_itens();
    this.price_order = 0;

    this.lastevent = Carrinho.get_last_event();

    let user = Auth.user();
    this.current_userid = user.id;

    Orders.get_itens(this.orders, Auth.token()).then((request) => {
      if (request.status == 200) {
        request.data.orders.map((orders, key) => {
          if (orders.lote == null) {
            request.data.orders.splice(key, 1);
            let carrinho = JSON.parse(window.localStorage.getItem('carrinho'));
            carrinho.splice(key, 1);
            window.localStorage.setItem('carrinho', JSON.stringify(carrinho));
          }
        });

        this.orders_infos = request.data.orders;
        this.price_order = request.data.price;
        this.tax_tkx = request.data.tax;


        if (request.data.price == 0) {
          this.goto_pay = false;
        } else {
          this.goto_pay = true;
        }

        this.loading = false;
      }
    });

  }
});
</script>

<style scoped>
.btn-proximo {
  color: #fff;
  background-color: #d31f28;
  border-color: #d31f28;
}

.padding-right-0 {
  padding-right: 0;
}

.img-card img {
  border-radius: 5px 0px 0px 5px;
  max-width: 100%;
  max-height: 100%;
}

.mt-4 {
  margin-top: 30px;
}

.mt-2 {
  margin-top: 15px;
}

.pr-4 {
  padding-right: 30px;
}

.card {
  border: 1px solid #dadada;
}

.price {
  padding-bottom: 6%;
  padding-top: 7%;

  padding-left: 0;

}

.valor span {
  color: #0f0f0f;
}

b {
  color: #eea72b;
}

.price span {
  color: #d31f28;
  cursor: pointer;
}</style>
